<template>
  <div class="receipt-note-detail">
    <a-spin :spinning="loading">
      <div class="content flex-ct between" style="min-height: 0">
        <a-tabs default-active-key="BaseInfo" @change="changeTabs">
          <a-tab-pane key="BaseInfo" :tab="$t(`基本信息`)"></a-tab-pane>
          <a-tab-pane key="AmountInfo" :tab="$t(`金额信息`)"></a-tab-pane>
          <a-tab-pane key="ProductInfo" :tab="$t(`明细`)"></a-tab-pane>
        </a-tabs>
        <div class="flex-ct" v-show="!loading">
          <OperateBtn
            v-if="formData.status === 'WAIT_CHECK'"
            api="operateReceiptNote"
            :text="$t('审核')"
            :params="{
              action: 'check',
              idList: [id],
            }"
            @success="init"
          />
          <OperateBtn
            v-if="formData.status === 'WAIT_RECEIVE' || formData.status === 'PART_RECEIVE'"
            class="ml10"
            api="operateReceiptNote"
            :text="$t('收款')"
            :params="{
              action: 'receive',
              idList: [id],
            }"
            @success="init"
          />
          <FindRelatedDocBtn class="ml10" :id="id" :docType="docType" v-if="isDetail" />
          <OperateBtn
            class="ml10"
            v-if="isDetail && ['DRAFT', 'WAIT_CHECK', 'WAIT_RECEIVE'].includes(formData.status)"
            api="operateReceiptNote"
            :text="$t('作废')"
            type="danger"
            :params="{
              action: 'cancel',
              idList: [id],
            }"
            @success="init"
          />
          <VoucherBtn
            class="ml10"
            v-if="
              isDetail &&
              !['DRAFT', 'WAIT_CHECK', 'CANCELLED'].includes(formData.status) &&
              !formData.voucherGenerated
            "
            :docType="docType"
            :selectedRows="[formData]"
            @close="init"
          />
        </div>
      </div>
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox
              id="BaseInfo"
              :title="$t('基本信息')"
              :showCopy="isDetail"
              @copyOrder="copyOrder"
            >
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款单号`)">
                    <a-input disabled v-model="formData.receiptNoteSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据类型`)" required>
                    <CommonSelect
                      :list="receipt_note_type"
                      :code.sync="formData.orderType"
                      :placeholder="$t('单据类型')"
                      :disabled="isDisabled || isFromPayable"
                      @change="changeOrderType"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款组织`)">
                    <CommonSelect
                      :isBu="true"
                      :list="$store.state.settlementBuList"
                      :disabled="isDisabled || isFromPayable"
                      :code.sync="formData.businessUnitCode"
                      :name.sync="formData.businessUnitName"
                      :placeholder="$t(`收款组织`)"
                      @change="changeBU"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算客户`)" required>
                    <CommonQuerySelect
                      api="getCommonList"
                      :disabled="isDisabled || isFromPayable"
                      :code.sync="formData.customerCode"
                      :name.sync="formData.customerName"
                      :params="{
                        businessUnitCode: formData.businessUnitCode,
                        tableName: 'customer',
                      }"
                      :placeholder="$t(`结算客户`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`业务日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.bizDate"
                      @choose="chooseBizDate"
                      :disabled="isDisabled"
                      :placehold="$t(`业务日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)">
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      :disabled="true"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`单据状态`)" required>
                    <a-input :value="receiptNoteStatusMapping[formData.status]" disabled />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6" v-if="isDetail">
                  <a-form-model-item :label="$t(`是否生成凭证`)">
                    <a-input
                      disabled
                      :value="+formData.voucherGenerated ? $t('是') : $t('否')"
                    ></a-input>
                  </a-form-model-item>
                </a-col>
              </a-row>
            </ModalBox>
            <ModalBox id="AmountInfo" :title="$t('金额信息')">
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算方式`)" required>
                    <CommonQuerySelect
                      api="getSettlementMethodList"
                      :placeholder="$t(`结算方式`)"
                      :code.sync="formData.settlementMethod"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`本位币`)">
                    <SelectCurrency :disabled="true" :code.sync="formData.functionalCurrency" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`收款货币`)">
                    <SelectCurrency :disabled="isDisabled" :code.sync="formData.receiptCurrency" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item required :label="$t(`应收金额`)">
                    <PriceInput
                      disabled
                      :currency="formData.receiptCurrency"
                      :value="formData.receivableAmount"
                      :placeholder="$t(`应收金额`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`折扣`)">
                    <PriceInput
                      :disabled="true"
                      :currency="formData.receiptCurrency"
                      :value="formData.discountAmount"
                      :placeholder="$t(`折扣`)"
                    />
                  </a-form-model-item>
                </a-col>
                <!-- <a-col :span="6">
                  <a-form-model-item :label="$t(`手续费`)">
                    <PriceInput
                      :disabled="true"
                      :currency="formData.receiptCurrency"
                      :value="formData.serviceCharge"
                      :placeholder="$t(`手续费`)"
                    />
                  </a-form-model-item>
                </a-col> -->
                <a-col :span="6">
                  <a-form-model-item :label="$t(`实收金额`)">
                    <PriceInput
                      :currency="formData.receiptCurrency"
                      :disabled="true"
                      :value.sync="formData.receiptAmount"
                      :placeholder="$t(`实收金额`)"
                    />
                  </a-form-model-item>
                </a-col>
                <template v-if="isDetail">
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`已核销金额`)">
                      <PriceInput
                        :currency="formData.receiptCurrency"
                        :disabled="true"
                        :value.sync="formData.verifiedAmount"
                      />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`未核销金额`)">
                      <PriceInput
                        :currency="formData.receiptCurrency"
                        :disabled="true"
                        :value.sync="formData.unVerifiedAmount"
                      />
                    </a-form-model-item>
                  </a-col>
                </template>
              </a-row>
            </ModalBox>
            <ModalBox id="ProductInfo" :title="$t('明细')">
              <template v-slot:header>
                <div class="option-btns pr20" v-if="!isDisabled">
                  <span class="text-link fz16" @click="addLineHandle">
                    {{ $t('增行') }}
                  </span>
                  <span class="text-link fz16 ml20" @click="deleteLineHandle">
                    {{ $t('删行') }}
                  </span>
                </div>
              </template>
              <CommonTable
                :columns="columns"
                :dataSource="tableData"
                :scroll="{ x: 1200 }"
                :showPagination="false"
                :showSetting="false"
                :showSelect="!isDisabled"
                :selectedRowKeys.sync="selectedRowKeys"
              >
                <template v-slot:receiptPurpose="{ record }">
                  <CommonSelect
                    :disabled="isDisabled"
                    :list="receiptPurposeList"
                    :code.sync="record.receiptPurpose"
                  />
                </template>
                <template v-slot:settlementCurrency="{ record }">
                  <SelectCurrency
                    :disabled="isDisabled || !!record.referDocDetailId"
                    :code.sync="record.settlementCurrency"
                  />
                </template>
                <template v-slot:receivableAmount="{ record }">
                  <PriceInput
                    :disabled="isDisabled"
                    :currency="record.settlementCurrency"
                    :value.sync="record.receivableAmount"
                    @blur="() => handleChange(record)"
                  />
                </template>
                <template v-slot:discountAmount="{ record }">
                  <PriceInput
                    :currency="record.settlementCurrency"
                    :disabled="isDisabled"
                    :value.sync="record.discountAmount"
                    @blur="() => handleChange(record)"
                  />
                </template>
                <template v-slot:serviceCharge="{ record }">
                  <PriceInput
                    :currency="record.settlementCurrency"
                    :disabled="isDisabled"
                    :value.sync="record.serviceCharge"
                    @blur="() => handleChange(record)"
                  />
                </template>
                <template v-slot:receiptAmount="{ text }">
                  <PriceInput
                    v-if="text"
                    :currency="formData.receiptCurrency"
                    :value="text"
                    :showTxt="true"
                  />
                </template>
                <template v-slot:remark="{ record }">
                  <a-input v-model="record.remark" :disabled="isDisabled"></a-input>
                </template>
              </CommonTable>
            </ModalBox>
          </a-form-model>
        </section>
        <OperationLog
          ref="OperationLogRef"
          :scrollHeight="scrollHeight"
          :actionSn="sn"
          :type="docType"
          v-if="isDetail"
        />
      </div>
      <BottomBtns
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import { mapState, mapGetters } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import SelectCurrency from '@component/selectCurrency'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { convertKeysToCamelCase, goBack } from '@/common'
import OperationLog from '@component/operationLog'
import { add, subtract, multiply } from '@/common/number'
export default {
  name: 'ReceiptNoteDetail',
  components: { DatePicker, BottomBtns, SelectCurrency, OperationLog },
  data() {
    return {
      id: undefined,
      sn: undefined,
      docType: 'ReceiptNote',
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      isFromPayable: false, //是否从应付下推过来
      formData: {
        receiptNoteSn: undefined,
        businessUnitCode: undefined,
        businessUnitName: undefined,
        orderType: undefined,
        bizDate: undefined,
        accountingDate: undefined,
        status: 'DRAFT',
        functionalCurrency: 'CNY',
        receiptCurrency: 'CNY',
        amountWithoutTax: undefined,
        taxAmount: undefined,
        receivableAmount: undefined,
        settlementMethod: undefined,
        discountAmount: undefined,
        serviceCharge: undefined,
        receiptAmount: undefined,
      },
      tableData: [],
      loading: false,
      draftLoading: false,
      saveLoading: false,
      selectedRowKeys: [],
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['receipt_note_type', 'currencyRateMapping', 'receipt_purpose']),
    ...mapGetters(['receiptNoteStatusMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 290 + 'px'
    },
    columns() {
      let list = [
        {
          title: this.$t('收款用途'),
          align: 'center',
          dataIndex: 'receiptPurpose',
          width: 120,
          scopedSlots: {
            customRender: 'receiptPurpose',
          },
        },
        {
          title: this.$t('结算货币'),
          align: 'center',
          dataIndex: 'settlementCurrency',
          width: 150,
          scopedSlots: {
            customRender: 'settlementCurrency',
          },
        },
        {
          title: this.$t('应收金额'),
          align: 'center',
          dataIndex: 'receivableAmount',
          width: 150,
          scopedSlots: {
            customRender: 'receivableAmount',
          },
        },
        {
          title: this.$t('折扣'),
          align: 'center',
          dataIndex: 'discountAmount',
          width: 120,
          scopedSlots: {
            customRender: 'discountAmount',
          },
        },
        // {
        //   title: this.$t('手续费'),
        //   align: 'center',
        //   dataIndex: 'serviceCharge',
        //   width: 120,
        //   scopedSlots: {
        //     customRender: 'serviceCharge',
        //   },
        // },
        {
          title: this.$t('实收金额'),
          align: 'center',
          dataIndex: 'receiptAmount',
          width: 120,
          scopedSlots: {
            customRender: 'receiptAmount',
          },
        },
        {
          title: this.$t('备注'),
          align: 'center',
          dataIndex: 'remark',
          width: 120,
          scopedSlots: {
            customRender: 'remark',
          },
        },
      ]
      return list
    },
    currencyRate() {
      return this.currencyRateMapping[this.formData.receiptCurrency] || 1
    },
    receiptPurposeList() {
      if (!this.formData.orderType) return this.receipt_purpose
      if (this.formData.orderType === 'ADVANCE_RECEIPT') {
        return this.receipt_purpose.filter((item) => item.code === 'ADVANCE_RECEIPT')
      }
      if (['SALE_RECEIPT', 'OTHER_RECEIPT'].includes(this.formData.orderType)) {
        return this.receipt_purpose.filter((item) =>
          ['ADVANCE_RECEIPT', 'SALE_RECEIPT'].includes(item.code)
        )
      }
      return this.receipt_purpose.filter((item) =>
        ['PURCHASE_REFUND', 'PREPAYMENT_REFUND'].includes(item.code)
      )
    },
  },
  methods: {
    init() {
      this.isDetail = false
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.bizDate = moment().format('YYYY-MM-DD')
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
      } else {
        this.isDetail = true
        this.id = this.$route.query.id
        this.sn = this.$route.query.sn
        this.$nextTick(() => {
          this.$refs.OperationLogRef.queryList()
        })
      }
      this.getDetailInfo()
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.code
      this.formData.businessUnitName = value.name
      this.formData.functionalCurrency = value.currency
    },
    changeOrderType() {
      this.tableData.forEach((item) => {
        item.receiptPurpose = undefined
      })
    },
    changeTabs(value) {
      document.querySelector(`#${value}`).scrollIntoView(true)
    },
    async getDetailInfo() {
      let receiptNoteFormdata = localStorage.getItem('receiptNoteFormdata'),
        pushTableList = localStorage.getItem('pushTableList')
      if (this.id) {
        this.loading = true
        let res = await http({
          url: api.getReceiptNoteDetail,
          data: {
            id: this.id,
          },
        })
        let result = convertKeysToCamelCase(res.result)
        this.formData = deepClone(result)
        this.tableData = deepClone(result.items).map((item) => {
          item.discountAmount = (item.discountAmount || 0) * 1
          return item
        })
        this.loading = false
      } else if (receiptNoteFormdata) {
        //复制
        let data = JSON.parse(receiptNoteFormdata)
        this.formData = data
        this.formData.receiptNoteSn = undefined
        this.formData.id = undefined
        this.formData.status = 'DRAFT'
        this.tableData = deepClone(this.formData.tableData)
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
        delete this.formData.tableData
        localStorage.removeItem('receiptNoteFormdata')
      } else if (pushTableList) {
        this.getFormData()
        let data = JSON.parse(pushTableList)
        // console.log('data: ', data)
        this.tableData = deepClone(data).map((item) => {
          return {
            receivableAmount: item.receivableAmount,
            receiptAmount: item.receiptAmount,
            discountAmount: '',
            remark: '',
            settlementCurrency: item.settlementCurrency,
            referDocDetailId: item.referDocDetailId,
            referDocId: item.referDocId,
            referDocSn: item.referDocSn,
            referDocType: item.referDocType,
            sourceDocDetailId: item.sourceDocDetailId,
            sourceDocId: item.sourceDocId,
            sourceDocSn: item.sourceDocSn,
            sourceDocType: item.sourceDocType,
          }
        })
        localStorage.removeItem('pushTableList')
        localStorage.removeItem('pushFormData')
      }
      this.$nextTick(() => {
        this.calcAllAmount()
      })
    },
    getFormData() {
      let pushFormData = JSON.parse(localStorage.getItem('pushFormData'))
      // console.log('pushFormData: ', pushFormData)
      this.formData.customerCode = pushFormData.customerCode || pushFormData.payeeCode
      this.formData.customerName = pushFormData.customerName || pushFormData.payeeName
      this.formData.businessUnitCode = pushFormData.businessUnitCode
      this.formData.businessUnitName = pushFormData.businessUnitName
      this.formData.functionalCurrency = pushFormData.functionalCurrency
      this.formData.receiptCurrency = pushFormData.settlementCurrency
      this.formData.settlementMethod = pushFormData.settlementMethod
      if (pushFormData.docType === 'PayableOrder') {
        //应付单下推过来
        this.isFromPayable = true
        this.formData.orderType = 'PURCHASE_REFUND'
      } else {
        this.formData.orderType = 'SALE_RECEIPT'
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    copyOrder() {
      this.formData.tableData = this.tableData
      let data = JSON.stringify(this.formData)
      localStorage.setItem('receiptNoteFormdata', data)
      this.$router.push({ name: 'receiptNoteDetail' })
    },
    backForm(back = 0) {
      goBack('receiptNoteList', back)
    },
    verification() {
      this.tableData.forEach((res, index) => {
        if (!res.receiptPurpose) {
          this.$warning({
            title: this.$t(`警告`),
            content: this.$t(`第X行请选择付款用途`, { X: index + 1 }),
          })
          throw Error()
        }
      })
      return Promise.resolve()
    },
    onSubmit: debounce(async function (status) {
      const data = deepClone(this.formData)
      if (status !== 'DRAFT') {
        if (!data.settlementMethod) {
          this.$warning({
            title: this.$t(`警告`),
            content: this.$t(`请选择结算方式`),
          })
          return
        }
        await this.verification()
      }
      data.status = status
      if (this.id) {
        data.id = this.id
      }
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.detailList = deepClone(this.tableData).map((item) => ({
        ...item,
        amountWithoutTax: item.receivableAmount,
        discountAmount: (item.discountAmount || 0) * 1,
        taxAmount: 0,
      }))
      await http({
        url: api.createReceiptNote,
        data: {
          receiptNoteReq: data,
        },
        type: 'post',
        hasLoading: true,
        success: (res) => {
          if (res.success) {
            this.$success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          } else {
            this.$error({
              title: this.$t(`错误`),
              content: this.$t(`创建失败`),
            })
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    addLineHandle() {
      this.tableData.push({
        receivableAmount: '',
        receiptAmount: '',
        discountAmount: '',
        remark: '',
        settlementCurrency: this.formData.functionalCurrency,
        receiptPurpose: undefined,
      })
    },
    deleteLineHandle() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.tableData = this.tableData.filter((item, index) => !this.selectedRowKeys.includes(index))
      this.selectedRowKeys = []
      this.calcAllAmount()
    },
    handleChange(record) {
      if (
        !this.$isEmpty(record.discountAmount) &&
        Math.abs(+record.discountAmount) > Math.abs(+record.receivableAmount)
      ) {
        this.$message.warning(this.$t('折扣金额不能大于应收金额'))
        record.discountAmount = record.receivableAmount
      }
      let receiptAmount = multiply(
        add(
          subtract(+record.receivableAmount, +record.discountAmount || 0),
          +record.serviceCharge || 0
        ),
        this.currencyRate
      ).toFixed(4)
      this.$set(record, 'receiptAmount', Number(receiptAmount))
      this.calcAllAmount()
    },
    calcAllAmount() {
      let receivableAmount = 0,
        discountAmount = 0,
        serviceCharge = 0,
        receiptAmount = 0
      this.tableData.forEach((item) => {
        receivableAmount = add(receivableAmount, +item.receivableAmount || 0)
        discountAmount = add(discountAmount, +item.discountAmount || 0)
        serviceCharge = add(serviceCharge, +item.serviceCharge || 0)
        receiptAmount = add(receiptAmount, +item.receiptAmount || 0)
      })
      this.$set(this.formData, 'receivableAmount', Number(receivableAmount.toFixed(4)))
      this.$set(this.formData, 'discountAmount', Number(discountAmount.toFixed(4)))
      this.$set(this.formData, 'serviceCharge', Number(serviceCharge.toFixed(4)))
      this.$set(this.formData, 'receiptAmount', Number(receiptAmount.toFixed(4)))
    },
  },
}
</script>
